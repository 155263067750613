import React, { useContext, useState, useEffect, useRef } from 'react';
import { Typography, Card, Tabs, Icon, Select, Form, Checkbox, Input, Button
	,Spin, Upload, Alert, DatePicker, notification } from 'antd';
import { ClientTaxonomyStoreContext } from './stores/Contexts';
import { observer } from 'mobx-react-lite';
import { getAbsoluteURL, buildTxtParentTree, TREE_SEP } from './common';
import moment from 'moment';
import MobileDetect from 'mobile-detect';

import Config from './Config';

const ManageData = (props) => {
	const mobileDetect = new MobileDetect(window.navigator.userAgent);
	const isMobile = mobileDetect.mobile() || window.innerWidth <= 576;

	return <div>
		<Typography.Title level={4} className='section-title'>
			Manage Data
		</Typography.Title>
		<Card>
			<Tabs defaultActiveKey='upload' tabPosition={isMobile ? 'top' : 'left'} className='manage-data-tabs'>
				<Tabs.TabPane tab={<span>Upload &nbsp;<Icon type='upload' /></span>} key='upload'>
					<WrappedUploadForm mode='upload' />
				</Tabs.TabPane>
				<Tabs.TabPane tab={<span>Delete &nbsp;<Icon type='delete' /></span>} key='delete'>
					<WrappedDeleteForm mode='delete' />
				</Tabs.TabPane>
				{/*<Tabs.TabPane tab={<span>Help &nbsp;<Icon type='question-circle' /></span>} key='help'>
					TBD
				</Tabs.TabPane>*/}
			</Tabs>
		</Card>
	</div>
}

const ManageDataForm = (props) => {
	const clientTaxonomyStore = useContext(ClientTaxonomyStoreContext);
	const [grantedUploads, setGrantedUploads] = useState([]);
	const [selectCampaignsDS, setSelectCampaignsDS] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectSearching, setSelectSearching] = useState(false);
	const [loggerMessages, setLoggerMessages] = useState([]);
	const timeoutRef = useRef(null);

	useEffect( () => {
		clientTaxonomyStore.getGrantedUploads().then( (grants) => {
			console.log('Grants:', grants);
			setGrantedUploads(grants);
		});
	}, []);

	const TYPE_OPTIONS = [
		{ label: 'Display', value: 'display', groupType: 'display' }
		,{ label: 'Display Reach', value: 'display-reach', groupType: 'display' }
		,{ label: 'Search Ecommerce', value: 'search-ecommerce', groupType: 'display' }
		,{ label: 'Programmatic (DBM)', value: 'dbm-programmatic', groupType: 'programmatic' }
		,{ label: 'Programmatic (Youtube)', value: 'youtube-programmatic', groupType: 'programmatic' }
		,{ label: 'Programmatic (Others)', value: 'others-programmatic', groupType: 'programmatic' }
		,{ label: 'Search Auctions (Google)', value: 'search-auctions', groupType: 'search' }
		,{ label: 'Search Auctions (Bing)', value: 'bing-search-auctions', groupType: 'search' }
		,{ label: 'TV Rubriche PRE', value: 'tv-rubriche-pre', groupType: 'tv' }
		,{ label: 'TV Reach PRE', value: 'tv-reach-pre', groupType: 'tv' }
		,{ label: 'TV Rubriche POST', value: 'tv-rubriche-post', groupType: 'tv' }
		,{ label: 'TV Reach POST', value: 'tv-reach-post', groupType: 'tv' }
		,{ label: 'TV Posizioni POST', value: 'tv-pos-post', groupType: 'tv' }
		,{ label: 'Radio Piano', value: 'radio-piano', groupType: 'radio' }
		//,{ label: 'Radio Focus', value: 'radio-focus', groupType: 'radio' }
		,{ label: 'Radio Ter', value: 'radio-ter', groupType: 'radio' }
		,{ label: 'Stampa Piano', value: 'stampa-piano', groupType: 'press' }
		,{ label: 'Stampa Comunicazione', value: 'stampa-comunicazione', groupType: 'press' }
		,{ label: 'Verification (IAS)', value: 'ias', groupType: 'verification' }
		,{ label: 'Verification Geo (IAS)', value: 'ias-geo', groupType: 'verification' }
		,{ label: 'Onlus Master', value: 'onlus-master', groupType: 'onlus' }
		,{ label: 'Investimenti', value: 'investimenti', groupType: 'investments' }
		,{ label: 'Investimenti 2024', value: 'investimenti-24', groupType: 'investments' }
		,{ label: 'Investimenti Forecast', value: 'investimenti-forecast', groupType: 'investments' }
		,{ label: 'Concorrenza AQX', value: 'investimenti-aqx', groupType: 'competition' }
		,{ label: 'Concorrenza Arianna', value: 'investimenti-arianna', groupType: 'competition' }
		,{ label: 'Concorrenza Anagrafica', value: 'concorrenza-mapping', groupType: 'competition' }
		,{ label: 'Other Channels', value: 'other-channels', groupType: 'others' }
	];
	const CLIENT_LEVEL_OPTIONS = ['display', 'display-reach', 'search-ecommerce', 'dbm-programmatic', 'youtube-programmatic', 'others-programmatic'
		,'search-auctions', 'bing-search-auctions', 'ias', 'ias-geo', 'onlus-master'
		,'investimenti', 'investimenti-24', 'investimenti-aqx', 'investimenti-arianna', 'investimenti-forecast', 'concorrenza-mapping', 'other-channels'
	];
	const HAS_CAMPAIGN_TYPES = ['tv-rubriche-pre', 'tv-reach-pre', 'tv-rubriche-post', 'tv-reach-post', 'tv-pos-post'];
	//the following constants are used in delete mode only 
	const DELETE_HAS_CAMPAIGN_TYPES = ['tv-rubriche-pre', 'tv-reach-pre', 'tv-rubriche-post', 'tv-reach-post', 'tv-pos-post'
		,'radio-piano', 'radio-focus', 'radio-ter', 'stampa-piano', 'stampa-comunicazione'];
	//has date types is used in delete mode
	const HAS_DATE_TYPES = ['display', 'display-reach', 'search-ecommerce', 'dbm-programmatic', 'youtube-programmatic', 'others-programmatic'
		,'search-auctions', 'bing-search-auctions', 'ias', 'ias-geo', 'onlus-master'
		,'investimenti', 'investimenti-24', 'investimenti-aqx', 'investimenti-arianna', 'investimenti-forecast', 'other-channels'
	];
	const HAS_END_DATE_TYPES = ['display', 'search-ecommerce', 'dbm-programmatic', 'youtube-programmatic', 'others-programmatic'
		,'search-auctions', 'bing-search-auctions'
		,'ias', 'ias-geo', 'onlus-master'
		,'investimenti', 'investimenti-24', 'investimenti-aqx', 'investimenti-arianna', 'investimenti-forecast', 'other-channels'];
	const DELETE_HAS_WEEK_TYPES = ['display-reach'];
	const UPLOAD_HAS_SIMULATION = ['display', 'search-ecommerce', 'display-reach', 'dbm-programmatic', 'youtube-programmatic', 'others-programmatic'
		,'investimenti', 'investimenti-24', 'investimenti-aqx', 'investimenti-arianna', 'investimenti-forecast', 'concorrenza-mapping'];

	const formItemLayout = {
		labelCol: {
			sm: { span: 24 },
			md: { span: 4 },
		},
		wrapperCol: {
			sm: { span: 24 },
			md: { span: 20 },
		},
	};
	const uploadProps = {
		accept: '.csv,text/csv'
		,beforeUpload: function(){ return false; }
	};

	const { getFieldDecorator } = props.form;
	const isDeleteMode = props.mode == 'delete';
	const formItems = isDeleteMode ?
		['uploadType', 'clientTaxonomyItem', 'campaign', 'date', 'end_date']
		: ['uploadType', 'clientTaxonomyItem', 'campaign', 'file'];

	const resetFormItemsAfter = (item) => {
		let toReset = formItems.slice( formItems.indexOf(item) );
		props.form.resetFields(toReset);
		setSelectCampaignsDS([]);
		setLoggerMessages([]);
	}

	const normFile = (e) => {
		console.log('Upload event:', e);
		//keeping just the last item of the array
		if (Array.isArray(e)) {
			return e.slice(-1);
		}
		return e && e.fileList.slice(-1);
	}

	const endDateGreaterThanStartValidator = (rule, value, callback) => {
		if (startDate && startDate.isAfter(value, 'day') ) {
			return callback( new Error('End date must be greater or equal to start date') );
		}
		return callback();
	}

	const handleSubmit = (e) => {
		//console.log('internal handle submit');
		e.preventDefault();
		props.form.validateFieldsAndScroll(null, { force: true }, (err, values) => {
			if (err) {
				return;
			}
			if (isDeleteMode) {
				_handleDeleteSubmit(values);
			}
			else {
				_handleUploadSubmit(values);
			}
		});
	}

	const _handleUploadSubmit = (values) => {
		setLoading(true);
		console.log(values);
		let formData = new FormData();
		formData.append('uploadType', values.uploadType);
		if (values.campaign) {
			formData.append('campaign', values.campaign);
		}
		if (values.simulate) {
			formData.append('simulate', values.simulate);
		}
		formData.append('file', values.file[0].originFileObj, values.file[0].name);
		clientTaxonomyStore.loadData(values.clientTaxonomyItem, formData).then( (response) => {
			if (response.info){
				setLoggerMessages(response.info);
			}
		}).catch(err => {
			if (err.response && err.response.data) {
				let data = err.response.data;
				if (data.error && data.error.info){
					setLoggerMessages(data.error.info);
				}
			}
                        notification.error({
                                message: 'Error',
                                description: `Cannot load the requested data. \n${err && err.message || ''}`,
                                duration: 8
                        }); 
		}).then( () => {
			setLoading(false);
		});
	};

	const _handleDeleteSubmit = (values) => {
		setLoading(true);
		if (values.uploadType) {
			if (DELETE_HAS_WEEK_TYPES.indexOf(values.uploadType) !== -1) {
				//automatic end date for types with single week timespan
				values.end_date = moment(values.date).startOf('day').add(7, 'days').toDate();
			}
		}
		console.log(values);
		clientTaxonomyStore.deleteData(values.clientTaxonomyItem, values).then( (response) => {
			if (response.info){
				setLoggerMessages(response.info);
			}
		}).catch(err => {
			if (err.response && err.response.data) {
				let data = err.response.data;
				if (data.error && data.error.info){
					setLoggerMessages(data.error.info);
				}
			}
                        notification.error({
                                message: 'Error',
                                description: `Cannot delete the requested rows. \n${err && err.message || ''}`,
                                duration: 8
                        }); 
		}).then( () => {
			setLoading(false);
		});
	}

	const onCampaignSearch = (searchText) => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
			timeoutRef.current = null;
		}
		timeoutRef.current = setTimeout( () => {
			if (searchText.length < 1){
				setSelectCampaignsDS([]);
				return
			}
			let queryType;
			let match = /(tv|radio|stampa)-/.exec(uploadType);
			if (match){
				let permission = match[1] != 'stampa' ? match[1] : 'press';
				queryType = `${permission}-campaigns`;
			}
			if (!queryType) {
				return;
			}
			setSelectSearching(true);
			clientTaxonomyStore.queryData(clientTaxonomyItem, {
				type: uploadType
				,queryType
				,searchText
			}).then( (items) => {
				let mappedItems = items.map( item => ({
					value: item.campaign, text: item.campaign
				}) );
				setSelectCampaignsDS(mappedItems);
			}).catch( (err) => {
			}).then( () => {
				setSelectSearching(false);
			});
		}, 550);

		return () => {
			clearTimeout(timeoutRef.current);
		};
	}

	const isDisabledDate = (currMomentDate) => {
		/*if (uploadType == 'search-auctions') {
			return (currMomentDate.day() != 1); //disabled if not monday
		}
		else if (uploadType == 'bing-search-auctions') {
			return (currMomentDate.day() != 0); //disabled if not sunday
		}
		else if (uploadType == 'display-reach') {
		*/

		if (uploadType == 'display-reach') {
			return (currMomentDate.day() != 0); //disabled if not sunday
		}
		return false;
	}


	const uploadType = props.form.getFieldValue('uploadType');
	const clientTaxonomyItem = props.form.getFieldValue('clientTaxonomyItem');
	const hasNewCampaignName = props.form.getFieldValue('hasNewCampaignName'); 
	const campaignName = props.form.getFieldValue('campaign'); 
	const file = props.form.getFieldValue('file'); 
	const startDate = props.form.getFieldValue('date'); 

	const hasCampaignName = (isDeleteMode ? DELETE_HAS_CAMPAIGN_TYPES : HAS_CAMPAIGN_TYPES).indexOf(uploadType) !== -1;
	const hasDate = isDeleteMode && HAS_DATE_TYPES.indexOf(uploadType) !== -1;
	const hasEndDate = hasDate && HAS_END_DATE_TYPES.indexOf(uploadType) !== -1;
	const uploadHasSimulate = !isDeleteMode && UPLOAD_HAS_SIMULATION.indexOf(uploadType) !== -1;
        const levelIsClient = CLIENT_LEVEL_OPTIONS.indexOf(uploadType) !== -1;
	const selectDS = grantedUploads.filter( (item, index, arr) => {
		let groupType = null;
		if (uploadType){
			let selectedOption = TYPE_OPTIONS.find( item => item.value == uploadType );
			if (selectedOption) {
				groupType = selectedOption.groupType;
			}
		}
		return	(item.resourceTypes || []).indexOf(groupType) !== -1 &&
			( item.clientTaxonomyItem && item.clientTaxonomyItem.level == (levelIsClient ? 'client' : 'brand') ) &&
			( arr.findIndex( el => el.clientTaxonomyItemId == item.clientTaxonomyItemId ) == index ) //this condition removes duplicates!
		;
	}).map( item => ({ value: item.clientTaxonomyItemId, text: buildTxtParentTree(item.clientTaxonomyItem).join(` ${TREE_SEP} `) }) );


	return <Form layout='horizontal' {...formItemLayout} onSubmit={handleSubmit}>

		<Form.Item label="Upload type">
		{getFieldDecorator('uploadType', {
			rules: [{ required: true, message: `Please select the type` }]
		})(
			<Select placeholder="Select an upload type" style={{ width: 250 }} allowClear={true} onChange={ () => {resetFormItemsAfter('uploadType')}}>
			{TYPE_OPTIONS.map( opt => (
				<Select.Option key={opt.value} value={opt.value}>{opt.label}</Select.Option>
			) )}
			</Select>
		)}
		</Form.Item>

		{uploadType &&
		<Form.Item label={levelIsClient ? 'Client' : 'Brand'} extra={selectDS.length == 0 ? <span className='log-error'>You lack permissions to perform this task</span>: null}>
		{getFieldDecorator('clientTaxonomyItem', {
			rules: [{ required: true, message: `Please select a ${levelIsClient?'client':'brand'} from the list` }]
		})(
			<Select defaultActiveFirstOption={false} showArrow={false} filterOption={false} allowClear={true}
			 onChange={ () => {resetFormItemsAfter('clientTaxonomyItem')}}>
				{selectDS.map( item => <Select.Option key={item.value} value={item.value}>{item.text}</Select.Option> )}
			</Select>
		)}
		</Form.Item>
		}

		{!isDeleteMode && hasCampaignName && clientTaxonomyItem && <React.Fragment>
			<Form.Item label='New campaign'>
			{getFieldDecorator('hasNewCampaignName',{
				valuePropName: 'checked'
			})(
				<Checkbox>This is a new campaign and I will type the campaign name by hand</Checkbox>
			)}
			</Form.Item>
			<Form.Item extra={hasNewCampaignName ? null : 'Select a campaign from the list. Type AT LEAST ONE LETTER to start searching'}
			 label={<React.Fragment>Campaign{selectSearching && <Spin size="small" style={{marginLeft: '5px'}} />}</React.Fragment>}>
			{!hasNewCampaignName ?
			getFieldDecorator('campaign', {
				rules: [{ required: true, message: `Please select a campaign from the list` }]
			})(
				<Select showSearch defaultActiveFirstOption={false} filterOption={false} allowClear={true}
				 onSearch={onCampaignSearch}>
					{selectCampaignsDS.map( item => <Select.Option key={item.value} value={item.value}>{item.text}</Select.Option> )}
				</Select>
			) : getFieldDecorator('campaign', {
				rules: [{ required: true, message: `Please insert a campaign name` }]
			})(
				<Input placeholder="campaign name" />
			) }
			</Form.Item>
		</React.Fragment>
		}
		{isDeleteMode && hasCampaignName && clientTaxonomyItem &&
			<Form.Item extra='Select a campaign from the list. Type AT LEAST ONE LETTER to start searching'
			 label={<React.Fragment>Campaign{selectSearching && <Spin size="small" style={{marginLeft: '5px'}} />}</React.Fragment>}>
			{getFieldDecorator('campaign', {
				rules: [{ required: true, message: `Please select a campaign from the list` }]
			})(
				<Select showSearch defaultActiveFirstOption={false} filterOption={false} allowClear={true}
				 onSearch={onCampaignSearch}>
					{selectCampaignsDS.map( item => <Select.Option key={item.value} value={item.value}>{item.text}</Select.Option> )}
				</Select>
			) }
			</Form.Item>
		}

		{!isDeleteMode && clientTaxonomyItem && (!hasCampaignName || (hasCampaignName && campaignName)) &&
		<Form.Item label='File'>
		{getFieldDecorator('file',{
			rules: [{ required: true, message: `Please select a file` }]
			,valuePropName: 'fileList'
			,getValueFromEvent: normFile
		})(
			<Upload {...uploadProps}>
				<Button>
					<Icon type="upload" /> Upload
				</Button>
			</Upload>
		)}
		</Form.Item>
		}
		{isDeleteMode && hasDate && clientTaxonomyItem && (!hasCampaignName || (hasCampaignName && campaignName)) && <React.Fragment>
		<Form.Item label={hasEndDate ? 'Start Date' : 'Date'}>
		{getFieldDecorator('date',{
			rules: [{ required: true, message: `Please select a date` }]
		})(
			<DatePicker format="YYYY-MM-DD" disabledDate={isDisabledDate} mode="date" allowClear />
		)}
		</Form.Item>

		{hasEndDate && 
		<Form.Item label='End Date'>
		{getFieldDecorator('end_date',{
			rules: [{ required: true, message: `Please select a date` }, { validator: endDateGreaterThanStartValidator }]
		})(
			<DatePicker format="YYYY-MM-DD" mode="date" allowClear />
		)}
		</Form.Item>
		}

		</React.Fragment>}

		{clientTaxonomyItem && (!hasCampaignName || (hasCampaignName && campaignName)) && <React.Fragment>
			{isDeleteMode &&
				<Form.Item label=' ' colon={false} extra="Just count the number of rows that would be deleted">
				{getFieldDecorator('simulate',{
					valuePropName: 'checked', initialValue: true
				})(
					<Checkbox>Perform a delete simulation</Checkbox>
				)}
				</Form.Item>
			}
			{uploadHasSimulate &&
				<Form.Item label=' ' colon={false} extra="Just simulate the effects of uploading your data">
				{getFieldDecorator('simulate',{
					valuePropName: 'checked', initialValue: true
				})(
					<Checkbox>Perform an upload simulation</Checkbox>
				)}
				</Form.Item>
			}
			<Form.Item label=' ' colon={false}>
				<Button type="primary" htmlType="submit" disabled={loading}>{isDeleteMode ? 'Delete data' : 'Load data'}</Button>
			</Form.Item>
		</React.Fragment>}
		{ loading && <Spin size="small" tip={isDeleteMode ? "Deleting data, please wait..." : "Loading data, please wait..."} /> }
		
		{loggerMessages && loggerMessages.length > 0 &&
		<Card size="small" title={isDeleteMode ? "Delete log" : "Upload log"}>
			{loggerMessages.map( (info, ndx) => (
				<div key={ndx} className={`log-${info.level}`}>{`[${info.timestamp}] ${info.message}`}</div>
			) )}
		</Card>
		}
	</Form>
}

const WrappedUploadForm = Form.create({ name: 'upload-data' })(ManageDataForm);
const WrappedDeleteForm = Form.create({ name: 'delete-data' })(ManageDataForm);

export default ManageData;
