import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';
import { ClientTaxonomyStoreContext } from './stores/Contexts';
import { Select, Form, DatePicker, Button, Spin, Statistic, Divider, notification, message, Typography, Tabs, Card, Icon } from 'antd';
import { buildTxtParentTree, TREE_SEP } from './common';
import csvStringify from 'csv-stringify';
import { saveAs } from 'file-saver';
import moment from 'moment';
import MobileDetect from 'mobile-detect';

const MAX_DATA_ROWS = {
	_default: 100000
	,'display_video_programmatic': 25000
	,'search_keywords_device': 50000
	,'search_adgroup_device': 50000
	,'social_allplatforms_ad': 50000
}

/*
KO:
	,{ label: 'Display/Video Floodlights', value: 'display_video_floodlights', groupType: 'displayprogrammatic-export'
		,desc: 'Floodlights AdServer data with breakdown according to naming conventions' }
	,{ label: 'Display Reach', value: 'display_reach', groupType: 'displayprogrammatic-export'
		,desc: 'Reach and Frequency data from AdServer according to campaign naming conventions' }
	,{ label: 'Search Segmented AdGroups Performance', value: 'search_segmented_adgroup_performance', groupType: 'search-export'
		,desc: 'Conversions Google Ads data with breakdown according to naming conventions' }
	,{ label: 'Search Quality Score', value: 'search_qualityscore_comparison', groupType: 'search-export'
		,desc: 'Google Ads data about Expected clickthrough rate, Ad relevance and Landing page experience. Quality Score is an aggregated estimate of how well a keyword has performed overall in past ad auctions' }

	,{ label: 'Social FB Ads', value: 'social_fb_ad', groupType: 'social-export'
		,desc: 'Facebook Business Manager data with breakdown according to naming conventions' }
	,{ label: 'Social FB Conversions', value: 'social_fb_actions_conversions', groupType: 'social-export'
		,desc: 'Conversions Facebook Business Manager data with breakdown according to naming conventions' }
	,{ label: 'Social FB Quality Ranking Comparison', value: 'social_fb_ad_qualityranking_comparison', groupType: 'social-export'
		,desc: 'Facebook Business Manager data about Quality ranking, Engagement rate ranking and Conversion rate ranking. Quality ranking explains how ad\'s perceived quality compared to ads competing for the same audience' }

*/

const RESOURCE_OPTIONS = [
	{ label: 'Display/Video + Programmatic', value: 'display_video_programmatic', groupType: 'displayprogrammatic-export'
		,desc: 'AdServer and Programmatic data joined on VVKUUID with breakdown according to naming conventions. Note: if there is no match, programmatic data is omitted' }
	,{ label: 'Display Reach Continuity', value: 'display_reach_continuity', groupType: 'displayprogrammatic-export'
		,desc: 'Reach and Frequency data from AdServer according to campaign naming conventions, continuity campaign' }
	,{ label: 'Display Reach Cumulative', value: 'display_reach_cumulative', groupType: 'displayprogrammatic-export'
		,desc: 'Reach and Frequency data from AdServer according to campaign naming conventions, flight campaign' }

	,{ label: 'Search Keywords', value: 'search_keywords_device', groupType: 'search-export'
		,desc: 'Google Ads and Bing or SA360 data with breakdown according to naming conventions, by keywords' }
	,{ label: 'Search AdGroups', value: 'search_adgroup_device', groupType: 'search-export'
		,desc: 'Google Ads and Bing or SA360 data with breakdown according to naming conventions, by adgroup' }
	,{ label: 'Search Auction Insights', value: 'search_auction_insights', groupType: 'search-export'
		,desc: 'Google Ads and Bing data of Auction Insights' }

	,{ label: 'Social', value: 'social_allplatforms_ad', groupType: 'social-export'
		,desc: 'Facebook Business Manager and TikTok data with breakdown according to naming conventions' }
	//,{ label: 'Social FB Quality Ranking', value: 'social_fb_ad_qualityranking', groupType: 'social-export' }

	,{ label: 'All Channels Overview', value: 'allchannels_overview', groupType: 'overview-export'
		,desc: 'All channels data with breakdown according to naming conventions' }

	,{ label: 'TV Daypart', value: 'tv_daypart_post', groupType: 'tv-export'
		,desc: 'PRE and POST data for television channel joined on campaign name, broadcaster and period' }
	,{ label: 'TV POST Positions', value: 'tv_pos_post', groupType: 'tv-export'
		,desc: 'Spot positions data for television channel' }
	//,{ label: 'TV Reach PRE', value: 'tv_reach_pre', groupType: 'tv-export' }
	,{ label: 'TV Reach POST', value: 'tv_reach_post', groupType: 'tv-export'
		,desc: 'POST Reach data for television channel' }

	,{ label: 'Radio Piano', value: 'radio_piano', groupType: 'radio-export'
		,desc: 'Radio data' }
	,{ label: 'Radio Ter', value: 'radio_ter', groupType: 'radio-export'
		,desc: 'Reach and Frequency data for radio channel' }

	,{ label: 'Stampa Piano', value: 'stampa_piano', groupType: 'press-export'
		,desc: 'Press data' }
	,{ label: 'Stampa Comunicazione', value: 'stampa_comunicazione', groupType: 'press-export'
		,desc: 'Reach and Frequency data for press channel' }

	,{ label: 'Onlus Master', value: 'onlus_master', groupType: 'onlus-export'
		,desc: 'Onlus Master' }

	,{ label: 'Other channels', value: 'other_channels', groupType: 'others-export'
		,desc: 'All the other available channels' }
];
const CLIENT_LEVEL_GROUP_OPTIONS = ['displayprogrammatic-export', 'search-export', 'social-export', 'overview-export', 'onlus-export', 'others-export'];
const NO_CAMPAIGN_RESOURCE_TYPES = ['search_auction_insights', 'allchannels_overview', 'onlus_master', 'other_channels'];

const ExportData = (props) => {
	const mobileDetect = new MobileDetect(window.navigator.userAgent);
	const isMobile = mobileDetect.mobile() || window.innerWidth <= 576;

	return <div>
		<Typography.Title level={4} className='section-title'>
			Export Data
		</Typography.Title>
		<Card>
			<Tabs defaultActiveKey='export' tabPosition={isMobile ? 'top' : 'left'} className='export-data-tabs'>
				<Tabs.TabPane tab={<span>Export &nbsp;<Icon type='export' /></span>} key='export'>
					<WrappedExportDataForm />
				</Tabs.TabPane>
				{/*<Tabs.TabPane tab={<span>Help &nbsp;<Icon type='question-circle' /></span>} key='help'>
					TBD
				</Tabs.TabPane>*/}
			</Tabs>
		</Card>
	</div>
}


function ExportDataForm(props) {
	const clientTaxonomyStore = useContext(ClientTaxonomyStoreContext);
	const [grantedUploads, setGrantedUploads] = useState([]);
	const [selectCampaignsDS, setSelectCampaignsDS] = useState([]);
	const [selectAllCampaignsDS, setSelectAllCampaignsDS] = useState([]);
	const [selectSearching, setSelectSearching] = useState(false);
	const [currentCount, setCurrentCount] = useState(null);
	const [countLoading, setCountLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const timeoutRef = useRef(null);


	const { getFieldDecorator } = props.form;
	const resourceType = props.form.getFieldValue('table');
	const resourceTypeItem = resourceType ? RESOURCE_OPTIONS.find(item => item.value == resourceType) : null;
	const resourceGroupType = resourceTypeItem ? resourceTypeItem.groupType : null;
        const levelIsClient = CLIENT_LEVEL_GROUP_OPTIONS.indexOf(resourceGroupType) !== -1;
	const clientTaxonomyItem = props.form.getFieldValue('clientTaxonomyItem');
	const hasCampaignName = resourceType && NO_CAMPAIGN_RESOURCE_TYPES.indexOf(resourceType) === -1;
	const dateRange = props.form.getFieldValue('dateRange');
	const selectedCampaigns = props.form.getFieldValue('campaigns');

	const maxDataRowsForThisTable = useMemo( () => {
		if (resourceType && MAX_DATA_ROWS.hasOwnProperty(resourceType)) {
			return MAX_DATA_ROWS[resourceType];
		}
		return MAX_DATA_ROWS._default;
	}, [resourceType]);

	const canDownloadData = currentCount > 0 && currentCount <= maxDataRowsForThisTable;

	useEffect( () => {
		clientTaxonomyStore.getGrantedUploads().then( (grants) => {
			console.log('Grants:', grants);
			setGrantedUploads(grants);
		});
	}, []);

	useEffect( () => {
		/*if (clientTaxonomyItem && hasCampaignName
		    && !selectSearching && selectCampaignsDS.length == 0)
		{*/
		if (clientTaxonomyItem && hasCampaignName
		    && !selectSearching && dateRange)
		{
			console.log('re-initializing campaigns DS');
			onCampaignSearch('', true);
		}
	}, [resourceType, clientTaxonomyItem, dateRange]);

	useEffect( () => {
		if (resourceType && clientTaxonomyItem && dateRange && (!hasCampaignName || selectedCampaigns)){
			console.log('fetching count');
			setCurrentCount(null);
			if (!hasCampaignName || selectedCampaigns.length > 0) {
				fetchRowsCount();
			}
		}
	}, [resourceType, clientTaxonomyItem, dateRange, selectedCampaigns]);

	const handleSubmit = (e) => {
		//console.log('internal handle submit');
		e.preventDefault();
		props.form.validateFieldsAndScroll(null, { force: true }, (err, values) => {
			if (err) {
				return;
			}
			_handleExport(values);
		});
	};

	const _handleExport = (values) => {
		setLoading(true);
		message.loading({ content: 'Please wait, this operation may take up to a few minutes in case of large datasets...', key: 'export', duration: 0 });
		clientTaxonomyStore.queryData(clientTaxonomyItem, {
			type: 'query-only'
			,queryType: `${resourceType}-data`
			,startDate: dateRange[0].format('YYYY-MM-DD')
			,endDate: dateRange[1].format('YYYY-MM-DD')
			,campaigns: selectedCampaigns
			,_extendedInfo: true
		}).then( (results) => {
			if (!results.schema || !results.rows) {
				throw new Error('wrong results format');
			}
			let csvColumns = results.schema.fields.map(item => item.name);
			message.success({ content: 'Data loaded, generating csv...', key: 'export', duration: 2.5 });
			let mappedRows = results.rows.map( row => {
				Object.keys(row).forEach( key => {
					if (typeof row[key] == 'object' && row[key] && row[key].hasOwnProperty('value')) {
						row[key] = row[key].value;
					}
				});
				return row;
			});
			csvStringify(mappedRows, { header: true, columns: csvColumns }, (err, data) => {
				let blob = new Blob([data], {type: "text/csv;charset=utf-8"});
				saveAs(blob, `${resourceType}_${moment().format('YYYY-MM-DDTHHmmss-SSS')}.csv`);
			});
		}).catch( (err) => {
			message.error({ content: 'Error loading data!', key: 'export', duration: 2 });
			let errMsg = err && err.response && err.response.data && err.response.data.error && err.response.data.error.message || err.message;
                        notification.error({
                                message: 'Error',
                                description: `Cannot load the requested data. \n${errMsg || ''}`,
                                duration: 8
                        }); 
		}).then( () => {
			setLoading(false);
		});
	};

	const fetchRowsCount = () => {
		setCountLoading(true);
		clientTaxonomyStore.queryData(clientTaxonomyItem, {
			type: 'query-only'
			,queryType: `${resourceType}-count`
			,startDate: dateRange[0].format('YYYY-MM-DD')
			,endDate: dateRange[1].format('YYYY-MM-DD')
			,campaigns: selectedCampaigns
		}).then( (results) => {
			setCurrentCount(results[0].count);
		}).catch( (err) => {
			console.error(err);
		}).then( () => {
			setCountLoading(false);
		});
	};

	const formItemLayout = {
		labelCol: {
			sm: { span: 24 },
			md: { span: 4 },
		},
		wrapperCol: {
			sm: { span: 24 },
			md: { span: 20 },
		},
	};


	const formItems = ['table', 'clientTaxonomyItem', 'campaigns', 'dateRange'];

	const resetFormItemsAfter = (item) => {
		let toReset = formItems.slice( formItems.indexOf(item) );
		props.form.resetFields(toReset);
		setSelectCampaignsDS([]);
	}

	const onCampaignSearch = (searchText, noDelay) => {
		//console.log('on campaign search');
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
			timeoutRef.current = null;
		}
		timeoutRef.current = setTimeout( () => {
			/*if (searchText.length < 1){
				setSelectCampaignsDS([]);
				return
			}*/
			let queryType = `${resourceType}-campaigns`;
			if (!queryType || !dateRange || !dateRange[0]) {
				return;
			}
			setSelectSearching(true);
			clientTaxonomyStore.queryData(clientTaxonomyItem, {
				type: 'query-only'
				,queryType
				,startDate: dateRange[0].format('YYYY-MM-DD')
				,endDate: dateRange[1].format('YYYY-MM-DD')
				,searchText
			}).then( (items) => {
				let mappedItems = items.map( item => ({
					value: item.campaign, text: item.campaign
				}) );
				setSelectCampaignsDS(mappedItems);
				if (!searchText) {
					setSelectAllCampaignsDS(mappedItems);
				}
			}).catch( (err) => {
			}).then( () => {
				setSelectSearching(false);
			});
		}, noDelay ? 0 : 550 );

		return () => {
			clearTimeout(timeoutRef.current);
		};
	};
	const onCampaignSelectedOrBlur = () => {
		//onCampaignSearch('', true);
		setSelectCampaignsDS( selectAllCampaignsDS );
	};

	const selectDS = grantedUploads.filter( (item, index, arr) => {
		return	(item.resourceTypes || []).indexOf(resourceGroupType) !== -1 &&
			( item.clientTaxonomyItem && item.clientTaxonomyItem.level == (levelIsClient ? 'client' : 'brand') ) &&
			( arr.findIndex( el => el.clientTaxonomyItemId == item.clientTaxonomyItemId ) == index ) //this condition removes duplicates!
		;
	}).map( item => ({ value: item.clientTaxonomyItemId, text: buildTxtParentTree(item.clientTaxonomyItem).join(` ${TREE_SEP} `) }) );

	return <Form layout='horizontal' {...formItemLayout} onSubmit={handleSubmit}>

		<Form.Item label="Table/Resource" extra={resourceTypeItem ? resourceTypeItem.desc : null}>
		{getFieldDecorator('table', {
			rules: [{ required: true, message: `Please select the table` }]
		})(
			<Select placeholder="Select a table" style={{ width: 350 }} allowClear={true} onChange={ () => {resetFormItemsAfter('table')}}>
			{RESOURCE_OPTIONS.map( opt => (
				<Select.Option key={opt.value} value={opt.value}>{opt.label}</Select.Option>
			) )}
			</Select>
		)}
		</Form.Item>

		{resourceType &&
		<Form.Item label={levelIsClient ? 'Client' : 'Brand'} extra={selectDS.length == 0 ? <span className='log-error'>You lack permissions to perform this task</span>: null}>
		{getFieldDecorator('clientTaxonomyItem', {
			rules: [{ required: true, message: `Please select a ${levelIsClient?'client':'brand'} from the list` }]
		})(
			<Select defaultActiveFirstOption={false} showArrow={false} filterOption={false} allowClear={true}
			 onChange={ () => {resetFormItemsAfter('clientTaxonomyItem')}}>
				{selectDS.map( item => <Select.Option key={item.value} value={item.value}>{item.text}</Select.Option> )}
			</Select>
		)}
		</Form.Item>
		}

		{clientTaxonomyItem &&
		<Form.Item label='Date Range'>
		{getFieldDecorator('dateRange', {
			rules: [{ required: true, message: `Please select a date range` }]
		})(
			<DatePicker.RangePicker format="YYYY-MM-DD" />
		)}
		</Form.Item>
		}

		{dateRange && hasCampaignName && <React.Fragment>
			<Form.Item extra='Select the desired campaigns from the list. Results are limited to 300 items per search criteria'
			 label={<React.Fragment>Campaigns{selectSearching && <Spin size="small" style={{marginLeft: '5px'}} />}</React.Fragment>}>
			{getFieldDecorator('campaigns', {
				rules: [{ required: true, message: `Please select at least one campaign from the list` }]
			})(
				<Select mode='multiple' showSearch defaultActiveFirstOption={false} filterOption={false} allowClear={true}
				 onSelect={onCampaignSelectedOrBlur} onBlur={onCampaignSelectedOrBlur}
				 onSearch={onCampaignSearch}>
					{selectCampaignsDS.map( item => <Select.Option key={item.value} value={item.value}>{item.text}</Select.Option> )}
				</Select>
			)}
			</Form.Item>
		</React.Fragment>
		}
		{resourceType && clientTaxonomyItem && dateRange && (!hasCampaignName || (selectedCampaigns && selectedCampaigns.length > 0) ) && <React.Fragment>
			<Divider />
			<Form.Item label=' ' colon={false}
			 validateStatus={countLoading ? 'validating' : (canDownloadData ? 'success' : 'error')}
			 extra={!countLoading && !canDownloadData ? <span className='log-error'>{`Download is disabled because the row count must be >0 and <= ${maxDataRowsForThisTable}`}</span> : null}>
				<Statistic title="Row Count" prefix={countLoading ? <Spin /> : '' } value={countLoading ? ' ' : (currentCount === null ? 'n/a' : currentCount)}  />
			</Form.Item>
			<Form.Item label=' ' colon={false}>
				<Button type="primary" htmlType="submit" disabled={!canDownloadData || countLoading || loading}>Download Data</Button>
			</Form.Item>
		</React.Fragment>
		}
	</Form>;
}

const WrappedExportDataForm = Form.create({ name: 'export-data' })(ExportDataForm);

export default ExportData;
