import React, { useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Link, navigate } from '@reach/router';
import { Avatar, Dropdown, Menu, Icon } from 'antd';
import { DashboardsStoreContext, AuthStoreContext, ClientTaxonomyStoreContext } from './stores/Contexts';
import { getAbsoluteURL, stripBaseURL, getDashboardURL, getDashTypeName, getFontAwesomeDashIcon, getUploadAndExportStatuses } from './common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getMenuItems, useMenu } from './Menu';
import logo from './logo.png';

const MainHeader = observer(
(props) => {
	const authStore = useContext(AuthStoreContext);
	const dashStore = useContext(DashboardsStoreContext);
	const clientTaxonomyStore = useContext(ClientTaxonomyStoreContext);
	const [uploadExportStatuses, setUploadExportStatuses] = useState({ canUpload: false, canExport: false });

	useEffect( () => {
		if (!authStore.canUploadOrExport) {
			setUploadExportStatuses({ canUpload: false, canExport: false });
			return;
		}
		clientTaxonomyStore.getGrantedUploads().then( (grants) => {
			console.log('Grants:', grants);
			let perms = getUploadAndExportStatuses(grants);
			setUploadExportStatuses(perms);
		});
	}, [authStore.canUploadOrExport]);

	function logout() {
		authStore.logout();
	}

	function gotoSettings(){
		navigate(getAbsoluteURL('/profile'));
	}

	const menu = getMenuItems(uploadExportStatuses);
	const [menuSelectedKeys, menuDefaultOpenKeys, hasDashboardNavigation, getDashboardsSubmenu, getMenu] = useMenu({
		pathname: props.pathname, clientDetailDashboards: dashStore.clientDetailDashboards, menu
	});

	const userDisplayName = authStore.name ? authStore.name : (authStore.email||'').replace(/@.*/,'');
	const avatarLetters = userDisplayName.split(/\s+|[._-]+/).map( tok => tok.charAt(0) ).join('').replace(/[W_]/g,'').slice(0,2).toUpperCase();

	const accountMenu = <Menu>
		<Menu.Item>
			<Avatar style={{ verticalAlign: 'middle' }} size="large">
				{userDisplayName && avatarLetters }
			</Avatar>
			<span className="user-name ellipsis">
				<strong>{userDisplayName}</strong><br/>
				<span>Organization: {authStore.organization}</span>
			</span>
		</Menu.Item>
		<Menu.Divider />
		<Menu.Item onClick={gotoSettings}>
			<Icon type="setting" /> Profile
		</Menu.Item>
		<Menu.Item onClick={logout}>
			<Icon type="logout" /> Logout
		</Menu.Item>
	</Menu>;

	const menuNodes = getMenu();
	if (hasDashboardNavigation) {
		const dashboardsSubmenuNodes = getDashboardsSubmenu();
		menuNodes.splice(1 + menu.findIndex( item => item.key == 'home'), 0, dashboardsSubmenuNodes);
	}

	return (
		<div className="clearfix main-header pos-relative">
			<span className="fl-left d-sm-none">
				<Icon className="trigger" type={props.sidebarCollapsed ? 'menu-unfold' : 'menu-fold'}
					onClick={props.toggleSidebar}
				/>
			</span>
			<div className="header-logo fl-left">
				<Link to={getAbsoluteURL('/')}>
					<img src={logo} className="logo" alt="logo" /><h1 style={{ width: '60px' }}></h1>
				</Link>
			</div>
			<Menu mode="horizontal" className="header-menu d-none d-sm-block" inlineIndent={20} theme="dark"
			 selectedKeys={menuSelectedKeys} style={{ lineHeight: '64px' }}
			>
			{ menuNodes }
			</Menu>
			{<div className="abs-right">
				<div className="user-info">
					<Dropdown overlay={accountMenu} trigger={['hover', 'click']}>
						<span className='d-inline-block'>
							<Avatar style={{ verticalAlign: 'middle' }} size="default">
								{userDisplayName && avatarLetters }
							</Avatar>
						</span>
					</Dropdown>
				</div>
			</div>
			}
		</div>
	);

} );

export default MainHeader;
