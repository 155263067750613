import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Router, Link, navigate } from "@reach/router";
import { match } from "@reach/router/lib/utils";
import { Layout, Menu, Icon, Result, Avatar, Dropdown } from 'antd';
import MobileDetect from 'mobile-detect';
import logo from './logo.png';
import Config from './Config';
import requiresAuthentication from './requiresAuthentication';
import MainHeader  from './Header';
import Dashboards from './Dashboards';
import DashboardDetail from './DashboardDetail';
import UserProfile from './UserProfile';
import ManageData from './ManageData';
import ExportData from './ExportData';
import { useMenu, getMenuItems, getBreadcrumb } from './Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAbsoluteURL, getUploadAndExportStatuses } from './common';
import { DashboardsStoreContext, AuthStoreContext, ClientTaxonomyStoreContext } from './stores/Contexts';

import BG0 from './images/login_full_bg0.jpg';
import BG1 from './images/login_full_bg1.jpg';
import BG3 from './images/login_full_bg3.jpg';
import BG4 from './images/login_full_bg4.jpg';
import BG5 from './images/login_full_bg5.jpg';
import BG6 from './images/login_full_bg6.jpg';
import BG7 from './images/login_full_bg7.jpg';

const SIMPLE_LAYOUT_BGS = [BG0, BG1, BG3, BG4, BG5, BG6, BG7];
const SIMPLE_LAYOUT_BGS_LENGTH = SIMPLE_LAYOUT_BGS.length;

function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}

const withSimpleLayout = (WrappedComponent, opts) => {
	opts = opts || {};
	return (props) => {
		const [year, setYear] = useState( new Date().getFullYear() );
		return (
			<Layout style={{ minHeight: '100vh' }} className='simple-layout'>
				{opts.hasBg && <img src={SIMPLE_LAYOUT_BGS[getRandomInt(0, SIMPLE_LAYOUT_BGS_LENGTH)]} className='full-bg animation-pulseSlow' />}
				<Layout.Content className='z-10'>
					<WrappedComponent {...props} />
				</Layout.Content>
				<Layout.Footer className='z-10'>
					Copyright © {String(year) === '2024' ? year : `2024-${year}`}. All rights reserved
				</Layout.Footer>
			</Layout>
		);
	}
}

const MainLayout = requiresAuthentication( observer(
(props) => {
	const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
	const [sidebarMenuOpenKeys, setSidebarMenuOpenKeys] = useState([]);
	const dashStore = useContext(DashboardsStoreContext);
	const authStore = useContext(AuthStoreContext);
	const clientTaxonomyStore = useContext(ClientTaxonomyStoreContext);
	const [uploadExportStatuses, setUploadExportStatuses] = useState({ canUpload: false, canExport: false });
	const [year, setYear] = useState( new Date().getFullYear() );

	const mobileDetect = new MobileDetect(window.navigator.userAgent);
	const isMobile = mobileDetect.mobile();

	const menu = getMenuItems(uploadExportStatuses);
	const [menuSelectedKeys, menuDefaultOpenKeys, hasDashboardNavigation, getDashboardsSubmenu, getMenu] = useMenu({
		pathname: props.location.pathname, clientDetailDashboards: dashStore.clientDetailDashboards, menu, isSidebar: true
	});

	useEffect( () => {
		setSidebarMenuOpenKeys(menuDefaultOpenKeys);
	}, [menuDefaultOpenKeys.join(',')]); //the join is super important because we're comparing content, not array references which change each time useMenu is called!

	useEffect( () => {
		if (!authStore.canUploadOrExport) {
			setUploadExportStatuses({ canUpload: false, canExport: false });
			return;
		}
		clientTaxonomyStore.getGrantedUploads().then( (grants) => {
			console.log('Grants:', grants);
			let perms = getUploadAndExportStatuses(grants);
			setUploadExportStatuses(perms);
		});
	}, [authStore.canUploadOrExport]);

	function toggleSidebar() {
		setSidebarCollapsed( !sidebarCollapsed );
	}

	function onSidebarCollapsed(collapsed){
		setSidebarCollapsed(collapsed);
	}

	function logout() {
		authStore.logout();
	}
	function gotoSettings(){
		navigate(getAbsoluteURL('/profile'));
	}
	/*
	function gotoUploadData(){
		navigate(getAbsoluteURL('/manage-data'));
	}*/

	function onSubMenuOpenChange(openKeys) {
		setSidebarMenuOpenKeys(openKeys);
	}

	const accountMenu = <Menu>
		<Menu.Item onClick={gotoSettings}>
			<Icon type="setting" /> Profile
		</Menu.Item>
		{/*<Menu.Item onClick={gotoUploadData}>
			<Icon type="upload" /> Manage Data 
		</Menu.Item>*/}
		<Menu.Item onClick={logout}>
			<Icon type="logout" /> Logout
		</Menu.Item>
	</Menu>;

	const userDisplayName = authStore.name ? authStore.name : (authStore.email||'').replace(/@.*/,'');
	const avatarLetters = userDisplayName.split(/\s+|[._-]+/).map( tok => tok.charAt(0) ).join('').replace(/[W_]/g,'').slice(0,2).toUpperCase();

	return (
	<Layout style={{ minHeight: '100vh' }}>
		<Layout.Sider className='d-sm-none' theme="dark" collapsible collapsed={sidebarCollapsed} onCollapse={onSidebarCollapsed}
		 collapsedWidth={isMobile ? 0 : 80} width={250}
		 trigger={null} breakpoint='md'
		>
			{/*<div className="sider-menu-logo">
				<Link to={getAbsoluteURL('/')}>
					<img src={logo} className="logo" alt="logo" /><h1>ADExpenditure</h1>
				</Link>
			</div>*/}
			<div className="user-info">
				<Dropdown overlay={accountMenu} trigger={['click']} overlayClassName='userinfo-menu-overlay'>
					<span className='d-inline-block'>
						<Avatar style={{ verticalAlign: 'middle' }} size="default">
							{userDisplayName && avatarLetters }
						</Avatar>
						{!sidebarCollapsed && <>
							<span className="user-name ellipsis">
								<strong>{userDisplayName}</strong><br/>
								<span>Organization: {authStore.organization}</span>
							</span>
							<Icon type="down" />
						</>
						}
					</span>
				</Dropdown>
			</div>
			<Menu theme="dark" mode="inline" className="sider-menu" inlineIndent={20}
			 selectedKeys={menuSelectedKeys} openKeys={!sidebarCollapsed ? sidebarMenuOpenKeys : []} onOpenChange={onSubMenuOpenChange}
			>
			{hasDashboardNavigation && !sidebarCollapsed && 
				getDashboardsSubmenu()
			}
			{ getMenu() }
			</Menu>
		</Layout.Sider>
		<Layout>
			<Layout.Header className="app-header">
				<MainHeader pathname={props.location.pathname}
				 sidebarCollapsed={sidebarCollapsed} toggleSidebar={toggleSidebar} />
			</Layout.Header>
			<Layout.Content className="app-content">
				<div className="main-panel">
					<Router>
						<Dashboards path="/" />
						<DashboardDetail path="/dashboards/:clientId" />
						<DashboardDetail path="/dashboards/:clientId/:dashboardId" />
						<UserProfile path="/profile" />
						<ManageData path="/manage-data" />
						<ExportData path="/export-data" />
						<NotFound default />
					</Router>
				</div>
			</Layout.Content>
			<Layout.Footer>Publicis ADExpenditure © {String(year) === '2024' ? year : `2024-${year}`} - All rights reserved</Layout.Footer>
		</Layout>
	</Layout>);
} ) );

function NotFound() {
        return (
                <Result status="404" title="404" subTitle="La pagina richiesta non esiste" />
        );
}

export { withSimpleLayout, MainLayout };
