import BaseStore from './BaseStore';
import { observable, computed, action, configure, decorate } from 'mobx';
import axios from 'axios';

import config from '../Config';

configure({ enforceActions: 'observed' }) // don't allow state modifications outside actions

const ROOT_ANCESTOR_LEVEL = '.';

class DashboardModel {
	id = null;
	name = '';
	embedURL = '';
	embedInfo = null;
	weight = null;
	clientTaxonomyItemId = '';

	static properties = ['name', 'embedURL', 'embedInfo', 'weight', 'clientTaxonomyItemId'];

	constructor(props) {
		this.id = props.id || null;
		DashboardModel.properties.forEach( key => {
			this[key] = props[key];
		});
	}
}
decorate(DashboardModel, {
	id: observable
	,name: observable
	,embedURL: observable
	,embedInfo: observable
	,weight: observable
	,clientTaxonomyItemId: observable
});

class DashboardsStore extends BaseStore {
	static standardLoadFilter = { include: [{ clientTaxonomyItem: { parent: { parent: { parent: { parent: 'parent' } } } } } ] };
	static standardFetchByIdFilter = { include: [{ clientTaxonomyItem: { parent: { parent: { parent: { parent: 'parent' } } } } } ] };

	favouriteDashboards = [];
	favouriteDashboardsLoaded = false;
	clientDetailDashboards = null;

	constructor(props) {
		super(props);
		//this.initialize();
	}

	setFavouriteDashboards(val) {
		this.favouriteDashboards = val;
	}

	setClientDetailDashboards(val) {
		this.clientDetailDashboards = val;
	}

	loadImplementation(opt) {
		let filter = { ...DashboardsStore.standardLoadFilter, ...(opt && opt.filter || {}) }
		return axios.get(config.apis.user.grantedDashboards, {
			params: { filter: JSON.stringify(filter) }
			, ...this.auth.getAuthOptions()
		}).then( (response) => {
			console.log(response.data);
			//this.setItems(response.data);
			return response.data;
		});
	}

	fetchById(id) {
		return axios.get(config.apis.user.grantedDashboardById(id), {
			params: { filter: JSON.stringify(DashboardsStore.standardFetchByIdFilter) }
			, ...this.auth.getAuthOptions()
		}).then( (response) => {
			console.log(response.data);
			return response.data;
		}).catch( (err) => {
			console.error(err);
			throw err;
		});
	}

	insertImplementation(values) {
		return axios.post(config.apis.dashboard.create, values,
			this.auth.getAuthOptions()
		).then( (response) => {
			console.log(response.data);
			return response.data;
		}).catch( (err) => {
			if (err.response && err.response.data && err.response.data.error){
				throw err.response.data.error;
			}
			throw err;
		});
	}

	deleteImplementation(id) {
		return axios.delete(config.apis.dashboard.delete(id),
			this.auth.getAuthOptions()
		).then( (response) => {
			console.log(response.data);
			return response.data;
		}).catch( (err) => {
			console.error(err);
			throw err;
		});
	}

	getFavouriteDashboards() {
		return axios.get(config.apis.user.favouriteDashboards, {
			...this.auth.getAuthOptions()
		}).then( (response) => {
			console.log(response.data);
			if (response.data){
				this.setFavouriteDashboards(response.data);
			}
			return response.data;
		}).catch( (err) => {
			console.error(err);
			throw err;
		}).then( action( () => {
			this.favouriteDashboardsLoaded = true;
		}) );
	}

	addFavouriteDashboard(id) {
		return axios.get(config.apis.user.addFavouriteDashboard(id), {
			...this.auth.getAuthOptions()
		}).then( (response) => {
			console.log(response.data);
			return response.data;
		}).catch( (err) => {
			console.error(err);
			throw err;
		});
	}

	removeFavouriteDashboard(id) {
		return axios.get(config.apis.user.removeFavouriteDashboard(id), {
			...this.auth.getAuthOptions()
		}).then( (response) => {
			console.log(response.data);
			return response.data;
		}).catch( (err) => {
			console.error(err);
			throw err;
		});
	}

}
decorate(DashboardsStore, {
	favouriteDashboards: observable.shallow
	,favouriteDashboardsLoaded: observable
	,setFavouriteDashboards: action
	,clientDetailDashboards: observable.shallow
	,setClientDetailDashboards: action
});

export { DashboardModel, DashboardsStore };
