import React, { useState, useContext } from 'react';
import { Menu, Breadcrumb, Icon, Badge } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAbsoluteURL, stripBaseURL, getDashboardURL, getDashTypeName, getFontAwesomeDashIcon } from './common';
import { Link } from "@reach/router";
import { match } from "@reach/router/lib/utils";

function getMenuItems({ canUpload, canExport }) {
	let menu = [
		{ path: '/', label: 'All Dashboards', key: 'home', icon: 'bar-chart'
			,children: []
				/*[	{ path: '/dashboards/:clientId', label: 'View Dashboard' }
				,{ path: '/dashboards/:clientId/:dashboardId', label: 'View Dashboard' }
				]*/
		}
	];
	if (canUpload) {
		menu.push( { path: '/manage-data', label: 'Upload Data', key: 'manage-data', icon: 'upload' } );
	}
	if (canExport) {
		menu.push( { path: '/export-data', label: 'Export Data', key: 'export-data', icon: 'download' } );
	}
	return menu;
}

function getDashboardsSubmenuNode({ firstDash, clientName, clientDetailDashboards, isSidebar }) {
	const dashNum = clientDetailDashboards.length;
	const subMenuTitle = (<span>
		{clientName}{!isSidebar && <><Badge count={dashNum} className='menu-dash-badge' /> <Icon type="caret-down" className='header-menu-arrow' /></>}
	</span>);

	return (
		<Menu.SubMenu key={`dashclient-${firstDash.clientTaxonomyItemId}`}
		 title={subMenuTitle}>
			{clientDetailDashboards.map( (dash) => (
			<Menu.Item key={`dash-${dash.id}`}>
				<Link to={getDashboardURL(firstDash.clientTaxonomyItemId, dash.id)}>
					<FontAwesomeIcon icon={getFontAwesomeDashIcon(dash)} className='anticon' />
					{getDashTypeName(dash)}{dash.favourite && <Icon type="star" className="favourite-star" theme="filled" />}
				</Link>
			</Menu.Item>
			))}
		</Menu.SubMenu>
	);
}

function getMenuNodes(menu) {
	return menu.map( (menuItem) => (
		<Menu.Item key={menuItem.key}>
			<Link to={getAbsoluteURL(menuItem.path)}>
				<Icon type={menuItem.icon} />
				<span>{menuItem.label}</span>
			</Link>
		</Menu.Item>
	) );
}

function getBreadcrumb(menu) {
	const pathArr = getMenuItemsPath(menu);
	return (
	<Breadcrumb>
		{pathArr.map( (pathItem, pathIndex) => (
		<Breadcrumb.Item key={pathIndex}>
			<Link to={getAbsoluteURL(pathItem.path)}>{pathIndex == 0 ? '/ ' : ''}{pathItem.label}</Link>
		</Breadcrumb.Item>
		) )}
	</Breadcrumb>
	);
}

function getMenuItemsPath(menu, pathname) {
	let pathArr = [];
	menu.find( (menuItem) => {
		let hasMatch = !! match(getAbsoluteURL(menuItem.path), pathname);
		if (hasMatch) {
			pathArr.push({ ...menuItem, path: stripBaseURL(pathname) });
		}
		else if (menuItem.children) { //no match but with children...
			let foundItem = menuItem.children.find( (subItem) => !!match(getAbsoluteURL(subItem.path), pathname) );
			hasMatch = foundItem ? true : false;
			if (hasMatch) {
				pathArr.push(menuItem, { ...foundItem, path: stripBaseURL(pathname) });
			}
		}
		return hasMatch;
	});
	return pathArr;
}

function getMenuSelectedKeys(menu, pathname, dashboardDetailRouteMatch, clientDetailDashboards) {
	let keys = [];
	const pathArr = getMenuItemsPath(menu, pathname);
	if (pathArr[0]) {
		keys.push( pathArr[0].key );
	}
	if (dashboardDetailRouteMatch) {
		if (clientDetailDashboards && clientDetailDashboards[0]){
			keys.push(`dashclient-${clientDetailDashboards[0].clientTaxonomyItemId}`);
		}
		keys.push(`dash-${dashboardDetailRouteMatch.params.dashboardId}`);
	}
	return keys;
	/*
	return menu.filter( (menuItem) => {
		let hasMatch = !! match(getAbsoluteURL(menuItem.path), props.location.pathname);
		if (!hasMatch && menuItem.children) {
			let found = menuItem.children.find( (subItem) => !!match(getAbsoluteURL(subItem.path), props.location.pathname) );
			hasMatch = found ? true : false;
		}
		return hasMatch;
	}).map( (menuItem) => menuItem.key);
	*/
}

const useMenu = ({ pathname, clientDetailDashboards, menu, isSidebar }) => {
	const getDashboardsSubmenu = () => {
		return getDashboardsSubmenuNode({ firstDash, clientName, clientDetailDashboards, isSidebar });
	}
	const getMenu = () => {
		return getMenuNodes(menu);
	}
	const dashboardDetailRouteMatch = match('/dashboards/:clientId/:dashboardId', pathname);
	const hasDashboardNavigation = dashboardDetailRouteMatch && clientDetailDashboards && clientDetailDashboards.length > 0;
	const firstDash = hasDashboardNavigation && clientDetailDashboards[0];
	const clientName = firstDash && firstDash.clientTaxonomyItem && firstDash.clientTaxonomyItem.name || '---';
	const menuSelectedKeys = getMenuSelectedKeys(menu, pathname, dashboardDetailRouteMatch, clientDetailDashboards);
	const menuDefaultOpenKeys = hasDashboardNavigation ? [`dashclient-${firstDash.clientTaxonomyItemId}`] : [];

	return [menuSelectedKeys, menuDefaultOpenKeys, hasDashboardNavigation, getDashboardsSubmenu, getMenu];
}


export { useMenu, getMenuItems, getBreadcrumb };
