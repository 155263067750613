import React, { useContext, useState, useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Spin, Result, Typography, Rate, message, Breadcrumb } from 'antd';
import ResponsiveEmbed from 'react-responsive-embed';

import { DashboardsStoreContext } from './stores/Contexts';

import { buildClientTaxonomyObjectsTree, orderDashboardsAndSetFavourite, getDashboardURL } from './common';
import Config from './Config';

const DashboardDetail = observer(
(props) => {
	const dashStore = useContext(DashboardsStoreContext);
	const [allDashboards, setAllDashboards] = useState(null);
	const [clientDashboardsReady, setClientDashboardsReady] = useState(false);
	const [savingFavourite, setSavingFavourite] = useState(false);
	const refCurrentDashboardId = useRef(null);

	const favouriteDashboards = dashStore.favouriteDashboards;

	useEffect( () => {
		if (props.location.state && props.location.state.allDashboards){
			console.log('Dashboards passed in an internal redirect');
			setAllDashboards(props.location.state.allDashboards);
		}
		else {
			dashStore.load({
				filter: { where: { clientTaxonomyItemId: props.clientId } }
			}).then( (dashboards) => {
				setAllDashboards(dashboards);
			});
		}
		if (!dashStore.favouriteDashboardsLoaded){
			dashStore.getFavouriteDashboards();
		}

		return function cleanup() {
			dashStore.setClientDetailDashboards(null); //it's used elsewhere in the app, let's reset it when we're unmounted
		}
	}, []);

	useEffect( () => {
		if (allDashboards && dashStore.favouriteDashboardsLoaded) {
			processDashboards(allDashboards);
		}
	}, [allDashboards, dashStore.favouriteDashboardsLoaded, dashStore.favouriteDashboards]); //this is like using a 'computed' observable in mobx

	useEffect( () => {
		if (props.dashboardId != refCurrentDashboardId.current && props.dashboardId){
			//during dashboard switches we need to reset the scrollTop
			refCurrentDashboardId.current = props.dashboardId;
			console.log('Scrolling to 0,0');
			window.scrollTo(0, 0);
			message.loading('Loading dashboard..', 3.5);
		}

		if (clientDashboardsReady && !props.dashboardId && dashStore.clientDetailDashboards[0]){
			let firstDash = dashStore.clientDetailDashboards[0];
			console.log('Redirecting to the first dashboard');
			props.navigate( getDashboardURL(props.clientId, firstDash.id), {
				state: { allDashboards }, replace: true
			} );
		}
	}, [clientDashboardsReady, props.dashboardId, dashStore.clientDetailDashboards]);

	function processDashboards(items) {
		const dashboards = orderDashboardsAndSetFavourite(items, favouriteDashboards);
		dashStore.setClientDetailDashboards(dashboards);
		setClientDashboardsReady(true);
	}

	function toggleFavouriteDashboard(val) {
		if (!props.dashboardId){
			return; //should never happen
		}
		let promise;
		setSavingFavourite(true);
		if (val == 0){
			promise = dashStore.removeFavouriteDashboard(props.dashboardId);
		}
		else {
			promise = dashStore.addFavouriteDashboard(props.dashboardId);
		}
		promise.then( (info) => {
			console.log(info);
			setSavingFavourite(false);
			dashStore.getFavouriteDashboards();
		});
	}

	const currentDashboard = clientDashboardsReady ?
		dashStore.clientDetailDashboards.find( item => (item.id == props.dashboardId && item.clientTaxonomyItemId == props.clientId) )
		: null;
	const finalEmbedURL = useMemo( () => {
		if (!currentDashboard) {
			return '';
		}
		if (props.location.hash && props.location.hash.match(/^#?page[/].+/) ) {
			console.log('replacing dashboard target page');
			return currentDashboard.embedURL.replace(/[/](page[/]\w+)(.*)$/, `/${props.location.hash.replace(/^#/, '')}$2` );
		}
		return currentDashboard.embedURL;
	}, [props.location, currentDashboard]);
	//console.log(props.location);

	if ( !clientDashboardsReady || (!props.dashboardId && dashStore.clientDetailDashboards[0]) ) {
		return <Spin tip="Loading..." size="large" />;
	}

	if (!currentDashboard){
		return <Result status="404" title="404"
			subTitle="Sorry, the requested dashboard does not exist or you don't have enough permissions to view it." />
	}

	const parentsTree = currentDashboard.clientTaxonomyItem ? buildClientTaxonomyObjectsTree(currentDashboard.clientTaxonomyItem) : null;

	const isFavouriteDashboard = favouriteDashboards && (favouriteDashboards.indexOf(props.dashboardId) != -1);
	const embedRatio = currentDashboard.embedInfo && Config.EMBED_INFO_BY_TYPE[currentDashboard.embedInfo.type]
		&& Config.EMBED_INFO_BY_TYPE[currentDashboard.embedInfo.type].ratio || '1:2';

	return (
	<div className="dashboard-detail">
		<div className="clearfix">
			{props.dashboardId &&
			<div className="fl-right">
				<div className="favourite-trigger">
					<Rate tooltips={isFavouriteDashboard ? ['Unset favourite'] : ['Set favourite']} count={1}
						value={isFavouriteDashboard ? 1 : 0}
						disabled={savingFavourite}
						onChange={toggleFavouriteDashboard} />
					{savingFavourite && <Spin style={{ marginLeft: 5 }} size="small" />}
				</div>
			</div>
			}
			{parentsTree &&
				<Breadcrumb separator="»" className='client-taxonomy-breadcrumb'>
				{ parentsTree.map( (item, ndx) => (
					<Breadcrumb.Item key={item.id}>{item.name}<span>{item.level}</span></Breadcrumb.Item>
				) )}
				</Breadcrumb>
			}
		</div>
		<div className="dash-container mt-15" style={{ minWidth: 300 }}>
			<ResponsiveEmbed  
				src={finalEmbedURL} allowFullScreen
				ratio={embedRatio} />
		</div>
	</div>
	);
} );

export default DashboardDetail;

