import { observable, computed, action, configure, decorate } from 'mobx';
import axios from 'axios';

import config from '../Config';

configure({ enforceActions: 'observed' }) // don't allow state modifications outside actions

class AuthenticationStore {
	email = null;
	token = null;
	userId = null;
	name = null;
	organization = null;
	_passwordExpired = null;
	canUploadOrExport = null;
	initialized = false;
	loggingIn = false;

	static properties = ['email', 'name', 'organization', '_passwordExpired', 'canUploadOrExport', 'token', 'userId'];

	get isAuthenticated() {
		if (!this.initialized){
			return null;
		}
		return !!this.token || !!this.userId;
	}

	constructor() {
		this.initialize();
	}

	getAuthHeadersValue(token) {
		return {
			// we can also use X-Access-Token
			'authorization': token
		}
	}

	getAuthOptions() {
		if (this.token){
			return {
				// we can also use X-Access-Token
				headers: this.getAuthHeadersValue(this.token)
			}
		}
		console.log('getAuthOptions returns withCredentials');
		return { withCredentials: true };
	}

	initialize() {
		console.log('initializing...');

		let authData;
		try { authData = JSON.parse(localStorage.getItem('authData'));
		} catch(err){ console.error(err); }
		console.log(authData);
		if (!authData){ //TODO: also check if token is still valid by comparing the dates
			action( () => { this.initialized = true } )();
			return;
		}
		this.setAccount({ token: authData.token });
		// perform ajax operation...
		axios.get(config.apis.user.current
			,this.getAuthOptions()
		).then( (userResponse) => {
			let calculatedProperties = ['canUploadOrExport'];
			console.log(userResponse);
			let authData = {};
			AuthenticationStore.properties.forEach( key => {
				if (calculatedProperties.indexOf(key) !== -1){
					return;
				}
				if (userResponse.data.hasOwnProperty(key)){
					authData[key] = userResponse.data[key];
				}
			});
			authData = {
				...authData
				,userId: userResponse.data.id
				,token: this.token
				,...this.getCalculatedProperties(userResponse.data)
			};
			console.log('still logged in...');
			this.setAccount(authData);
		}).catch( (err) => {
			console.error(err);
			localStorage.removeItem('authData');
			this.setAccount(null);
		}).then( action(() => {
			this.initialized = true;
		}));
	}

	getCalculatedProperties(userData) {
		return {
			canUploadOrExport: !!userData.canUpload
		};
	}

	setAccount(data) {
		if (data === null){
			AuthenticationStore.properties.forEach( key => {
				this[key] = null;
			});
			return;
		}
		AuthenticationStore.properties.forEach( key => {
			this[key] = data.hasOwnProperty(key) ? data[key] : null;
		});
	}

	patchAccount(data) {
		Object.keys(data).forEach( key => {
			this[key] = data[key];
		});
	
	}

	setLoggingIn(value) {
		this.loggingIn = value;
	}

	login(params) {
		//console.log(params);
		this.setLoggingIn(true);
		return axios.post(config.apis.user.login, {
			email: params.email
			,password: params.password
		}, this.getAuthOptions()).then( (authResponse) => { //id, created, ttl, userId
			console.log('authresponse', authResponse);
			let authData = {
				token: authResponse.data.id
				,userId: authResponse.data.userId
			};
			console.log(authData);
			this.setAccount(authData);
			localStorage.setItem('authData', JSON.stringify(authData));
			this.setLoggingIn(false);
			this.initialize();
		}).catch( (err) => {
			this.setLoggingIn(false);
			console.error('Error logging in', err);
			throw err;
		});
	}

	logout() { 
		return axios.post(config.apis.user.logout, null, this.getAuthOptions()).then( () => {
			this.setAccount(null);
			localStorage.removeItem('authData');
		});
	}

	requestPasswordReset(params) {
		console.log(`Requesting password reset for ${params.email}`);
		return axios.post(config.apis.user.requestPasswordReset, {
			email: params.email
		}, this.getAuthOptions()).then( (response) => {
			console.log(response);
		});
	}

	resetPassword(params) {
		console.log(`resetting the password for token ${params.token}`);
		return axios.post(config.apis.user.resetPassword, {
			newPassword: params.password
		}, {
			headers: this.getAuthHeadersValue(params.token)
		}).then( (response) => {
			console.log(response);
		});
	}

	requestAccess(params) {
		console.log(`Requesting access for ${params.email}`);
		return axios.post(config.apis.user.requestAccess, {
			email: params.email
		}, this.getAuthOptions()).then( (response) => {
			console.log(response);
		});
	}

	verify(params) {
		console.log(`Requesting verification mail for id ${params.id}`);
		return axios.post(config.apis.user.verify(params.id), {
		}, this.getAuthOptions()).then( (response) => {
			console.log(response);
		});
	}
}
decorate(AuthenticationStore, {
	email: observable
	,token: observable
	,userId: observable
	,name: observable
	,organization: observable
	,_passwordExpired: observable
	,canUploadOrExport: observable
	,initialized: observable
	,loggingIn: observable
	,isAuthenticated: computed
	,initialize: action
	,setAccount: action
	,patchAccount: action
	,setLoggingIn: action
});

export default AuthenticationStore;
