import React, { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect, Link } from "@reach/router";
import { Spin, Typography, Icon, Form, Input, Button, notification, Row, Col } from 'antd';
import { AuthStoreContext, UsersStoreContext } from './stores/Contexts';
import Config from './Config';

import { withSimpleLayout } from './Layouts';
import { getAbsoluteURL, validatePassword } from './common';

const Register = withSimpleLayout( observer( (props) => {
	const authStore = useContext(AuthStoreContext);
	const userStore = useContext(UsersStoreContext);
	const redirectTo = props.location.state && props.location.state.from ? props.location.state.from : Config.MOUNT_POINT;

	if (authStore.isAuthenticated === null){
		return (<div>
			Checking authentication... <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
		</div>);
	}
	else if (authStore.isAuthenticated === false){
		return (<div className="register-form-container page-form-container">
			<div className='title-container'>
				<Typography.Title level={2} className="text-center">Register to ADExpenditure</Typography.Title>
			</div>
			<RegisterForm userStore={userStore} />
		</div>);
	}
	//if we're here, we're logged in
	return <Redirect to={redirectTo} noThrow />
}), { hasBg: true } );

const RegisterForm = Form.create({
        name: 'register-form'
})(observer( (props) => {
        const { getFieldDecorator } = props.form;
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	function handleSubmit(e) {
		setIsSubmitting(true);
		props.form.validateFields((err, values) => {
			e.preventDefault();
			if (err) {
				console.error(err);
				setIsSubmitting(false);
				return;
			}
			delete values['email-confirm'];
			
			props.userStore.insert(values).then( () => {
				setIsSubmitted(true);
			}).catch( (err) => {
				notification.error({
					message: 'Error registering'
					,description: `Cannot register user: ${err.message}`
					,duration: 5
				});
			}).then( () => {
				setIsSubmitting(false);
			});
		});
	}

	const validateEmailConfirmation = (rule, value, callback) => {
		if (value && value !== props.form.getFieldValue('email')) {
			return callback('Mail mismatch');
		}
		return callback();
	};

	if (isSubmitted){
		return <Redirect to={getAbsoluteURL('/login')} noThrow />
	}

	return (
		<Form className="register-form" layout='vertical' onSubmit={handleSubmit}>
			<Form.Item label='Email'>
			{ getFieldDecorator('email', {
				rules: [ { type: 'email', message: 'Please insert a valid email' }, { required: true, message: 'Please insert your email' }
				]
			})( <Input prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />} type="text" placeholder="email@example.com" name="email" />) }
			</Form.Item>
			<Form.Item label='Confirm Email'>
			{ getFieldDecorator('email-confirm', {
				rules: [ { required: true, message: 'Confirm your email' }, { validator: validateEmailConfirmation }
				]
			})( <Input prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />} type="text" placeholder="email@example.com" name="email" />) }
			</Form.Item>
			<Form.Item label='Password'>
			{ getFieldDecorator('password', {
				rules: [ { required: true, message: 'Insert your password' }, { validator: validatePassword } ]
			})( <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="password" name="password" />) }
			</Form.Item>
			<Form.Item>
				<Row type="flex" justify="space-between">
					<Col>
						<Link to={getAbsoluteURL('/login')}>Login</Link><br/>
					</Col>
					{/*<Col>
						<Link to={getAbsoluteURL('/password-forgotten')}>Password Forgotten</Link>
					</Col>*/}
				</Row>
			</Form.Item>
			<Form.Item>
				<Button className="submit" type="primary" htmlType="submit" disabled={isSubmitting} block>Register</Button>
			</Form.Item>
		</Form>
	);
}));

export default Register;
