import BaseStore from './BaseStore';
import { observable, computed, action, configure, decorate } from 'mobx';
import axios from 'axios';

import config from '../Config';

configure({ enforceActions: 'observed' }) // don't allow state modifications outside actions

const ROOT_ANCESTOR_LEVEL = '.';

class ClientTaxonomyItemModel {
	id = null;
	name = '';
	level = '';
	parentId = '';
	logo = '';

	static properties = ['name', 'level', 'parentId', 'logo'];

	constructor(props) {
		this.id = props.id || null;
		ClientTaxonomyItemModel.properties.forEach( key => {
			this[key] = props[key];
		});
	}
}
decorate(ClientTaxonomyItemModel, {
	id: observable
	,email: observable
	,name: observable
});

class ClientTaxonomyStore extends BaseStore {
	levels = [ROOT_ANCESTOR_LEVEL, 'mediagroup', 'agency', 'client', 'country', 'division', 'brand'];
	_grantedUploadsCache = null;
	_grantedUploadsPromise = null;

	expandedKeys = [];

	setExpandedKeys(keys) {
		this.expandedKeys = keys;
	}

	getAncestorLevelName(currLevel) {
		let index = this.levels.indexOf(currLevel);
		if (index == -1) {
			return null;
		}
		return this.levels[index-1];
	}

	getChildLevelName(currLevel) {
		let index = this.levels.indexOf(currLevel);
		if (index == -1) {
			return null;
		}
		return this.levels[index+1];
	}

	getKeysPath(key) {
		let trail = [];
		let targetItem = this.items.find( (item) => item.id == key );
		if (!targetItem) {
			return trail;
		}
		if (targetItem.parentId) {
			trail = this.getKeysPath(targetItem.parentId);
		}
		return trail.concat(targetItem.id);
	}

	get itemsTree() {
		let map = {};
		let keyIsChildren = {};
		let output = [];
		this.items.forEach( (item) => {
			map[item.id] = { title: item.name, id: item.id, key: item.id, level: item.level, parentId: item.parentId };
		});
		//we'll iterate over map because this.items isn't mapped and is missing title and key props
		Object.keys(map).forEach( (key) => {
			let item = map[key];
			if (item.parentId && map[item.parentId]) {
				if (!map[item.parentId].hasOwnProperty('children')){
					map[item.parentId].children = [];
				}
				map[item.parentId].children.push(item);
				keyIsChildren[item.id] = true;
			}
		});
		Object.keys(map).forEach( (key) => {
			if (keyIsChildren[key]){
				return;
			}
			output.push(map[key]);
		});
		console.log(output);
		return output;
	}

	constructor(props) {
		super(props);
		//this.initialize();
	}


	loadImplementation(opt) {
		return axios.get(config.apis.clientTaxonomyItem.find, 
			this.auth.getAuthOptions()
		).then( (response) => {
			console.log(response.data);
			this.setItems(response.data);
			return response.data;
		});
	}

	fetchById(id) {
		return axios.get(config.apis.clientTaxonomyItem.findById(id),
			this.auth.getAuthOptions()
		).then( (response) => {
			console.log(response.data);
			return response.data;
		}).catch( (err) => {
			console.error(err);
			throw err;
		});
	}

	insertImplementation(values) {
		return axios.post(config.apis.clientTaxonomyItem.create, values,
			this.auth.getAuthOptions()
		).then( (response) => {
			console.log(response.data);
			return response.data;
		}).catch( (err) => {
			if (err.response && err.response.data && err.response.data.error){
				throw err.response.data.error;
			}
			throw err;
		});
	}

	updateImplementation(values) {
		//we're patching, not really replacing the attributes
		return axios.patch(config.apis.clientTaxonomyItem.update(values.id), values,
			this.auth.getAuthOptions()
		).then( (response) => {
			console.log(response.data);
			return response.data;
		}).catch( (err) => {
			console.error(err);
			throw err;
		});
	}

	deleteImplementation(id) {
		return axios.delete(config.apis.clientTaxonomyItem.delete(id),
			this.auth.getAuthOptions()
		).then( (response) => {
			console.log(response.data);
			return response.data;
		}).catch( (err) => {
			console.error(err);
			throw err;
		});
	}

	fetchChildrenCount(id) {
		return axios.get(config.apis.clientTaxonomyItem.countChildren(id),
			this.auth.getAuthOptions()
		).then( (response) => {
			console.log(response.data);
			if (!response.data || !response.data.hasOwnProperty('count')){
				throw new Error('unrecognized response from server');
			}
			return response.data.count;
		}).catch( (err) => {
			console.error(err);
			throw err;
		});
	}

	find(opt) {
		return axios.get(config.apis.clientTaxonomyItem.find, {
			...this.auth.getAuthOptions()
			,params: { filter: JSON.stringify(opt) }
		}).then( (response) => {
			console.log(response.data);
			this.setItems(response.data);
			return response.data;
		});
	}

	getGrantedUploads() {
		if (this._grantedUploadsCache) {
			return Promise.resolve(this._grantedUploadsCache);
		}
		if (this._grantedUploadsPromise) {
			return this._grantedUploadsPromise;
		}
		this._grantedUploadsPromise = axios.get(config.apis.user.grantedUploads, {
			...this.auth.getAuthOptions()
		}).then( (response) => {
			console.log('Fetched', response.data);
			this._grantedUploadsCache = response.data;
			delete this._grantedUploadsPromise;
			return response.data;
		}).catch( (err) => {
			console.error(err);
			delete this._grantedUploadsPromise;
			throw err;
		});
		return this._grantedUploadsPromise;
	}

	loadData(id, formData) {
		let axiosOpts = this.auth.getAuthOptions();
		if (!axiosOpts.headers){
			axiosOpts.headers = {};
		}
		axiosOpts.headers['Content-Type'] = 'multipart/form-data';
		return axios.post(config.apis.clientTaxonomyItem.loadData(id), formData
			,axiosOpts
		).then( (response) => {
			console.log(response.data);
			return response.data;
		}).catch( err => {
			console.error(err);
			throw err;
		});
	}

	deleteData(id, values) {
		return axios.post(config.apis.clientTaxonomyItem.deleteData(id), values 
			,this.auth.getAuthOptions()
		).then( (response) => {
			console.log(response.data);
			return response.data;
		}).catch( err => {
			console.error(err);
			throw err;
		});
	}

	queryData(id, values) {
		//console.log('query data');
		return axios.post(config.apis.clientTaxonomyItem.queryData(id), values 
			,this.auth.getAuthOptions()
		).then( (response) => {
			console.log(response.data);
			return response.data;
		}).catch( err => {
			console.error(err);
			throw err;
		});
	}
}
decorate(ClientTaxonomyStore, {
	itemsTree: computed
	,expandedKeys: observable.shallow
	,setExpandedKeys: action
});

export { ClientTaxonomyItemModel, ClientTaxonomyStore };
