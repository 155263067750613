import BaseStore from './BaseStore';
import { observable, action, configure, decorate } from 'mobx';
import axios from 'axios';

import config from '../Config';

configure({ enforceActions: 'observed' }) // don't allow state modifications outside actions

class UserModel {
        id = null;
        email = '';
        name = '';
        organization = '';

        static properties = ['email', 'name', 'organization'];

        constructor(props) {
                this.id = props.id || null;
                UserModel.properties.forEach( key => {
                        this[key] = props[key];
                });
        }
}
decorate(UserModel, {
        id: observable
        ,email: observable
        ,name: observable
});

class UsersStore extends BaseStore {
        //roles = [];

        constructor(props) {
                super(props);
                //this.initialize();
        }

        fetchById(id) {
                return axios.get(config.apis.user.findById(id),
                        this.auth.getAuthOptions()
                ).then( (response) => {
                        console.log(response.data);
                        return response.data;
                }).catch( (err) => {
                        console.error(err);
                        throw err;
                });
        }
        updateImplementation(values, id) {
                //we're patching, not really replacing the attributes
                return axios.patch(config.apis.user.update(id || values.id), values,
                        this.auth.getAuthOptions()
                ).then( (response) => {
                        console.log(response.data);
                        return response.data;
                }).catch( (err) => {
                        console.error(err);
                        throw err;
                });
        }

        deleteImplementation(id) {
                return axios.delete(config.apis.user.delete(id),
                        this.auth.getAuthOptions()
                ).then( (response) => {
                        console.log(response.data);
                        return response.data;
                }).catch( (err) => {
                        console.error(err);
                        throw err;
                });
        }


	insertImplementation(values) {
		return axios.post(config.apis.user.create, values,
			this.auth.getAuthOptions()
		).then( (response) => {
			console.log(response.data);
			return response.data;
		}).catch( (err) => {
			if (err.response && err.response.data && err.response.data.error){
				throw err.response.data.error;
			}
			throw err;
		});
	}

        search(opt) {
                return axios.get(config.apis.user.find, {
                        ...this.auth.getAuthOptions()
                        ,params: { filter: JSON.stringify(opt.filter) }
                }).then( (response) => {
                        console.log(response.data);
                        return response.data;
                });
        }
}

export { UserModel, UsersStore };
