import { observable, action, configure, decorate } from 'mobx';

configure({ enforceActions: 'observed' }) // don't allow state modifications outside actions

class BaseStore {
	items = []
	isLoading = false;
	loaded = false;
	dirty = false;

	auth = null;
	skipUpdateLocalItem = false;

	constructor(opts) {
		opts = opts || {};
		this.baseModel = opts.model || null;

		if (opts.auth){
			this.auth = opts.auth;
		}
	}

	setItems(items){
		this.items = [];
		if (this.baseModel){
			this.items.push.apply(this.items, items.map( item => new this.baseModel(item) ));
		} else {
			this.items.push.apply(this.items, items);
		}
	}

	load(opt) {
		console.log('fetching...');
		this.isLoading = true;
		return this.loadImplementation(opt).then( action((items) => {
			this.isLoading = false;
			this.loaded = true;
			this.dirty = false;
			return items;
		}) ).catch( action((err) => {
			this.isLoading = false;
			throw err;
		}) );
	}

	insert(values) {
		return this.insertImplementation(values).then( action((item) => {
			this.dirty = true;
			return item;
		}) );
	}

	update(values, id){
		return this.updateImplementation(values, id).then( (item) => {
			if (item && !this.skipUpdateLocalItem){
				/*if (!this.baseModel){
					throw new Error('You must provide a base model to the store if you want to update items');
				}*/
				this.updateLocalItem(item);
			}
			return item;
		});
	}

	updateLocalItem(values){
		if (!this.baseModel || !this.baseModel.properties){
			/*throw new Error('the base model should have a static "properties" defined');*/
			//console.log('no correct basemodel provided, extending the object');
			let filtered = this.items.filter( (item) => item.id == values.id );
			filtered.forEach( item => {
				Object.assign(item, values);
			});
			return;
		}
		let filtered = this.items.filter( (item) => item.id == values.id );	
		filtered.forEach( item => {
			this.baseModel.properties.forEach( key => {
				item[key] = values[key];
			});
		});
	}

	delete(id) {
		return this.deleteImplementation(id).then( () => {
			this.deleteLocalItem(id);
			return id;
		});
	}

	deleteLocalItem(id) {
		let index = this.items.findIndex( (item) => item.id == id );
		if (index != -1){
			this.items.splice(index, 1);
		}
	}
}
decorate(BaseStore, {
	items: observable.shallow
	,isLoading: observable
	,loaded: observable
	,dirty: observable
	,setItems: action.bound
	,load: action.bound
	,updateLocalItem: action.bound
	,deleteLocalItem: action.bound
});

export default BaseStore;

