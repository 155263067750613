import React, { useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { DashboardsStoreContext } from './stores/Contexts';
import { Spin, Typography, Collapse, List, Card, Icon, Divider, Row, Col, Drawer, Badge, Input, notification } from 'antd';
import { Link } from "@reach/router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Conf from './Config';

import { buildClientTaxonomyTree, orderDashboardsAndSetFavourite, getDashboardURL, getDashTypeName, getFontAwesomeDashIcon } from './common';

const Dashboards = observer(
(props) => {
	const dashStore = useContext(DashboardsStoreContext);
	const [allDashboards, setAllDashboards] = useState(null);
	const [sublevelVisible, setSublevelVisible] = useState(false);
	const [selectedSublevel, setSelectedSublevel] = useState(null);
	const [searchString, setSearchString] = useState(null);
	const favouriteDashboards = dashStore.favouriteDashboards;

	useEffect( () => {
		dashStore.load().then( (dashboards) => {
			setAllDashboards(dashboards);
		}).catch( err => {
			notification.error({
				message: 'Cannot retrieve the list of dashboards'
				,description: `You might not have the required permissions. Please try again or contact the Administrator: ${err.message}`
				,duration: 5
			});
		});
		if (!dashStore.favouriteDashboardsLoaded){
			dashStore.getFavouriteDashboards();
		}
	}, []);

	const groupedDashboards = React.useMemo( () => {
		if (allDashboards && dashStore.favouriteDashboardsLoaded) {
			return processDashboards(allDashboards);
		}
		return null;
	}, [allDashboards, dashStore.favouriteDashboardsLoaded, searchString]); //this is like using a 'computed' observable in mobx


	function processDashboards(items) {
		//first level: object with "client levels"
		//second level: array of objects { name, parentsTree, logoUrl, dashboards (array), clientId }
		let groupByLevelAndClient = {};
		items.forEach( (item) => {
			let level = item.clientTaxonomyItem && item.clientTaxonomyItem.level || 'N/A';
			if (!groupByLevelAndClient.hasOwnProperty(level)){
				groupByLevelAndClient[level] = [];
			}
			groupByLevelAndClient[level].push(item);
		});
		Object.keys(groupByLevelAndClient).forEach( level => {
			let groupByClient = {};
			groupByLevelAndClient[level].forEach( item => {
				let clientId = item.clientTaxonomyItemId || 'N/A';
				if (!groupByClient.hasOwnProperty(clientId)){
					groupByClient[clientId] = [];
				}
				groupByClient[clientId].push(item);
			});
			groupByLevelAndClient[level] = getListItemDataFromGroupedDashboards(groupByClient, searchString);
		});
		return groupByLevelAndClient;
	}

	function getListItemDataFromGroupedDashboards(groupedByLevel, searchString) {
		const data = Object.keys(groupedByLevel).flatMap( (clientId) => {
			const firstItem = groupedByLevel[clientId][0];
			const name = firstItem.clientTaxonomyItem ? firstItem.clientTaxonomyItem.name : 'N/A';

			if ( searchString && !String(name).toLowerCase().includes(searchString.toLowerCase()) ) {
				return []; //removing the item because it doesn't match our filter
			}

			const dashboards = orderDashboardsAndSetFavourite(groupedByLevel[clientId], favouriteDashboards);
			const logoUrl = firstItem.clientTaxonomyItem && firstItem.clientTaxonomyItem.logo ?
				`${Conf.urls.clientTaxonomyItemLogo(firstItem.clientTaxonomyItem.logo.url)}`
				: null

			return [{
				name: name
				,parentsTree: firstItem.clientTaxonomyItem ? buildClientTaxonomyTree(firstItem.clientTaxonomyItem) : null
				,logoUrl: logoUrl
				,dashboards: dashboards
				,clientId: clientId
			}]
		});
		data.sort( (a,b) => {
			return a.name.localeCompare(b.name);
		});
		return data;
	}

	function gotoDashboard(clientId) {
		props.navigate(getDashboardURL(clientId, null));
	}

	if (!groupedDashboards) {
		return <Spin tip="Loading..." size="large" />;
	}

	const clientTaxonomyLevels = [
		{ name: 'Brand', value: 'brand' }
		,{ name: 'Division', value: 'division' }
		,{ name: 'Country', value: 'country' }
		,{ name: 'Client', value: 'client' }
		,{ name: 'Agency', value: 'agency' }
		,{ name: 'Media Group', value: 'mediagroup' }
	];

	const activeLevels = Object.keys(groupedDashboards);

	/*const dashboardsGridsConf = {
		gutter: 16, xs: 1, sm: 2, md: 2, lg: 2, xl: 3, xxl: 3
	};*/
	const dashboardsGridsConf = {
		gutter: [8, 8], xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 6
	};

	console.log(groupedDashboards);

	function getDashClassType(dash) {
		const type = dash.embedInfo ? dash.embedInfo.type : 'unknown';
		return type;
	}

	function getDashIcon(dash) {
		const icon = getFontAwesomeDashIcon(dash);
		return (
			<span className='dash-ico'>
				<FontAwesomeIcon icon={icon} title={dash.name} />
			</span>
		);
	}

	function showSublevel(item) {
		setSelectedSublevel(item);
		setSublevelVisible(true);
	}

	function levelListItemRenderer(item) {
		const onClick = () => { showSublevel(item); };
		const textTitle = item.parentsTree ? item.parentsTree.join(' » ') : item.name;
		const textTitleAbbr = item.parentsTree ? item.parentsTree.slice(-3).join(' » ') : item.name;
		return (
		<List.Item>
			<Card hoverable
			 /*title={<small className='parents-tree' title={textTitle}>{textTitleAbbr}</small>}*/
			 size='small'
			 onClick={onClick}>
				<div className='logo-container'>
					<Badge className='dash-count' count={item.dashboards.length}
					 style={{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }} />
					{item.logoUrl ?
						  <div className='logo-bg' style={{ backgroundImage: `url("${item.logoUrl}")` }}
						   title={textTitle}
						   onClick={onClick} />
						  : <h2>{item.name}</h2>
					}
				</div>
			</Card>
		</List.Item>
		)
	}

	function sublevelItemRenderer(dash) {
		return (
		<div>
			<div key={dash.id} className={`dash-item col-${getDashClassType(dash)}`}>
				<Link key={dash.id} to={getDashboardURL(selectedSublevel.clientId, dash.id)}>
					{getDashIcon(dash)} <small>{getDashTypeName(dash)}</small>
					{dash.favourite && <Icon className="favourite-star" type="star" theme="filled" />}
				</Link>
			</div>
		</div>);
	}

	function onDrawerClose() {
		setSublevelVisible(false);
	}

	function onSearchChanged(e) {
		const { value } = e.target;
		setSearchString(value);
	}

	return <div className="my-dashboards">
		<Typography.Title level={4} className='section-title'>My Dashboards</Typography.Title>
		<Input.Search className="mb-15" placeholder="search..." onChange={onSearchChanged} allowClear style={{ width: '100%' }} />
		{activeLevels.length > 1 && <Collapse defaultActiveKey={activeLevels} bordered={true}>
			{clientTaxonomyLevels.filter(levelDef => (groupedDashboards[levelDef.value] && groupedDashboards[levelDef.value].length > 0))
			.map( levelDef => (
				<Collapse.Panel key={levelDef.value} header={levelDef.name} disabled={activeLevels.indexOf(levelDef.value) == -1}>
					{groupedDashboards[levelDef.value] &&
					<List className='level-list' grid={dashboardsGridsConf}
						dataSource={groupedDashboards[levelDef.value]}
						renderItem={levelListItemRenderer}
					/>
					}
				</Collapse.Panel>
			) )}
		</Collapse>
		}
		{activeLevels.length == 1 &&
		clientTaxonomyLevels.filter(levelDef => (groupedDashboards[levelDef.value] && groupedDashboards[levelDef.value].length > 0))
		.map( levelDef => (
			<List key={levelDef.value} className='level-list mb-5' grid={dashboardsGridsConf}
				dataSource={groupedDashboards[levelDef.value]}
				renderItem={levelListItemRenderer}
			/>
		) )}

		{selectedSublevel &&
		<Drawer visible={sublevelVisible} title={selectedSublevel.name} placement='right' closable={true} onClose={onDrawerClose}>
			<List dataSource={selectedSublevel.dashboards} renderItem={sublevelItemRenderer} />
		</Drawer>
		}
	</div>
} );

export default Dashboards;
