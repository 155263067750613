import React from 'react';
import AuthenticationStore from './AuthStore';
import { UsersStore } from './UsersStore';
import { ClientTaxonomyStore } from './ClientTaxonomyStore';
import { DashboardsStore } from './DashboardsStore';

const authStore = new AuthenticationStore();
const usersStore = new UsersStore({ auth: authStore });
const clientTaxonomyStore = new ClientTaxonomyStore({ auth: authStore });
const dashboardsStore = new DashboardsStore({ auth: authStore });

export const AuthStoreContext = React.createContext(authStore);
export const UsersStoreContext = React.createContext(usersStore);
export const ClientTaxonomyStoreContext = React.createContext(clientTaxonomyStore);
export const DashboardsStoreContext = React.createContext(dashboardsStore);

