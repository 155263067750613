import React, { useEffect, useContext } from 'react';
import { AuthStoreContext } from './stores/Contexts';
import { observer } from 'mobx-react-lite';
import Config from './Config';
import { getAbsoluteURL } from './common';

//requiresAuthentication now also checks for password expiration
const requiresAuthentication = (WrappedComponent) => {
	return observer(
		(props) => {
			const authStore = useContext(AuthStoreContext);

			useEffect( () => {
				if (!authStore.isAuthenticated) {
					console.log(`not authenticated, from:`, props.location);
					props.navigate(getAbsoluteURL('/login'), { state: { from: props.location.pathname+props.location.search+props.location.hash } });
				}
				if (authStore._passwordExpired) {
					console.log(`password expired, from:`, props.location);
					props.navigate(getAbsoluteURL('/password-expired'), { state: { from: props.location.pathname+props.location.search+props.location.hash } });
				}
			}, [authStore.isAuthenticated, authStore._passwordExpired]);

			return (
			<React.Fragment>
				{ !authStore.isAuthenticated &&
				<div>Not authenticated... redirecting</div>
				}
				{ authStore.isAuthenticated && authStore._passwordExpired &&
				<div>Password expired... redirecting</div>
				}
				{ authStore.isAuthenticated && !authStore._passwordExpired &&
				<WrappedComponent {...props} />
				}
			</React.Fragment>
			);
		}
	);
}

export default requiresAuthentication;
