import React, { useContext, useState, useEffect, useRef } from 'react';
import { Typography, Spin, Card, Skeleton, Row, Col } from 'antd';
import { Form, Input, Button, notification } from 'antd';
import { UsersStoreContext, AuthStoreContext } from './stores/Contexts';
import { observer } from 'mobx-react-lite';
import { getAbsoluteURL, validatePassword } from './common';

import Config from './Config';

const UserProfile = observer(
(props) => {
        const usersStore = useContext(UsersStoreContext);
	const authStore = useContext(AuthStoreContext);
        const [itemLoaded, setItemLoaded] = useState(false);
        const [loading, setLoading] = useState(false);
        const [saving, setSaving] = useState(false);
        const [fields, setFields] = useState({ email: { value: '' }, name: { value: '' }, organization: { value: '' }, password: { value: '' } });

        useEffect( () => {
                if (itemLoaded) {
                        return;
                }
		usersStore.fetchById('me').then( (fetchedItem) => {
                        let out = {};
                        Object.keys(fetchedItem).forEach( key => {
                                out[key] = { value: fetchedItem[key] };
                        });
                        setItemLoaded(true);
                        setFields({ ...fields, ...out });
                }).catch( (err) => {
                        notification.error({
                                message: 'Error',
                                description: `Cannot fetch the user. Please try again.\n${err && err.message || ''}`,
                                duration: 8
                        }); 
                }).then( () => {
                        setLoading(false);
                });
        }, []);

        function handleFormChange(changedFields) {
                //console.log('handle form change', changedFields);
                setFields({ ...fields, ...changedFields });
        }

        function handleSubmit(values) {
                console.log('Received values of form: ', values);
                setSaving(true);
		let patch = { ...values };
		if (!patch.password){
			delete patch.password;
		}
		usersStore.update(patch, 'me').then( (user) => {
			authStore.patchAccount(user);
                        notification.success({
                                message: 'Success',
                                description: 'User saved successfully',
                                duration: 4
                        });
                }).catch( (err) => {
                        console.log(err);
                        notification.error({
                                message: 'Error',
                                description: `Cannot save the user. Please try again.\n${err && err.message || ''}`,
                                duration: 8
                        });
                }).then( () => {
                        setSaving(false);
                });
        }

        return (
                <React.Fragment>
                        <Typography.Title level={3}>
                                Edit Profile { loading ? <Spin size="default" /> : null }
                        </Typography.Title>
                        <Card>  
				<Row>
					<Col lg={11}>
						<Skeleton active loading={loading} paragraph={{ rows: 3 }}>
							<UserEditForm
								{...fields}
								userId={'me'} saving={saving}
								onChange={handleFormChange} onSubmit={handleSubmit}
							/>
						</Skeleton>
					</Col>
				</Row>
                        </Card>
                </React.Fragment>
        );
});

const UserEditForm = Form.create({
        name: 'user-edit',
        onFieldsChange(props, changedFields) {
                props.onChange(changedFields);
        },
        mapPropsToFields(props) {
                //console.log(props);
                return {
                        email: Form.createFormField({
                                ...props.email,
                                value: props.email.value,
                        }),
                        name: Form.createFormField({
                                ...props.name,
                                value: props.name.value,
                        }),
                        organization: Form.createFormField({
                                ...props.organization,
                                value: props.organization.value,
                        }),
                        password: Form.createFormField({
                                ...props.password,
                                value: props.password.value,
                        }),
                };
        },
        /*onValuesChange(_, values) {
                console.log(values);
        },*/
})((props) => {
        const formItemLayout = {
                labelCol: {
                        xs: { span: 24 },
                        sm: { span: 7 },
                },
                wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 17 },
                },
        };
        const { getFieldDecorator } = props.form;

        let handleSubmit = (e) => {
                //console.log('internal handle submit');
                e.preventDefault();
                props.form.validateFieldsAndScroll((err, values) => {
                        if (!err) {
                                props.onSubmit(values);
                        }
                });
        };

        return (
                <Form className='user-edit' {...formItemLayout} onSubmit={handleSubmit}>
                        <Form.Item label='E-mail'>
                        { getFieldDecorator('email', {
                                rules: [ { type: 'email', message: 'The input is not valid E-mail' }
                                         ,{ required: true, message: 'Please insert the E-mail' }
                                ]
                        })( <Input type="email" placeholder="E-mail" />) }
                        </Form.Item>
                        <Form.Item label='Name'>
                        { getFieldDecorator('name', {
                        })( <Input placeholder="name and surname" />) }
                        </Form.Item>
                        <Form.Item label='Organization'>
                        { getFieldDecorator('organization', {
                                rules: [ { required: true, message: 'Please insert the user\'s organization' } ]
                        })( <Input placeholder="Organization" />) }
                        </Form.Item>
                        <Form.Item label='Password' extra="Leave empty if you don't want to change your password">
                        { getFieldDecorator('password', {
				rules: [ { validator: validatePassword } ]
			})( <Input.Password autoComplete='new-password' />) }
                        </Form.Item>
                        <Form.Item label=' ' colon={false}>
                                <Button type="primary" htmlType="submit" disabled={props.saving}>Update information</Button>
                        </Form.Item>
                        { props.saving && <Spin size="small" tip="Saving..." /> }
                </Form>
        );
});

export default UserProfile;

