import React from 'react';
import { Router, Redirect } from "@reach/router";

import './App.css';
import Config from './Config';
import Login from './Login';
import Register from './Register';
import PasswordForgotten from './PasswordForgotten';
import PasswordReset from './PasswordReset';
import PasswordExpired from './PasswordExpired';
import { MainLayout } from './Layouts';

function App() {
	return (
		<div className="App">
			<Router basepath={Config.MOUNT_POINT}>
				<Login path="/login" />
				<Register path="/register" />
				<PasswordExpired path="/password-expired" />
				<PasswordForgotten path="/password-forgotten" />
				<PasswordReset path="/password-reset/:token" />
				<MainLayout path="/*" />
			</Router>
			{Config.MOUNT_POINT != '/' &&
			/* redirect / to mount point when needed */
			<Router>
				<Redirect from='/' to={Config.MOUNT_POINT} noThrow />
			</Router>
			}
		</div>
	);
}


export default App;
